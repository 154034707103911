import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Pagination from '@material-ui/lab/Pagination'
import { Person as PersonIcon } from '@material-ui/icons'
import ActionsMenu from '../../component/actions-menu'
import NotificationsMenu from '../../component/notifications-menu'
import Button from '@material-ui/core/Button'
import Modal from '../../component/modal'
import { saveUser } from '../../domain/user'
import renderHTML from 'react-render-html'
import verifySaveUserErrorType from '../../helpers/verify-save-user-error-type'
import validateEmailFormat from '../../helpers/validate-email-format'

import CustomerTypeUi from '../customer-type/style'

import { sendNotification } from '../../domain/notifications'

import {
  listAll,
  listCustomersEmails
} from '../../domain/account/customers-emails'

import Tooltip from '../../component/tooltip'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../../component/expansion-panel'

import Checkbox from '../../component/checkbox'
import ButtonSearch from '../button-search'
import InputText from '../../component/input'
import Loading from '../../component/loading'
import CheckboxEmail from './checkbox'
import CheckboxEmailDaily from './checkbox-daily'
import CheckboxDispute from './checkbox-dispute'

import {
  Container,
  GridContainer,
  TitlePage,
  FormSearchCustomer,
  ContainerPagination,
  Box,
  IconButtonUi
} from './styles'
import Swal from 'sweetalert2'
import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core'

export default () => {
  const { t } = useTranslation()

  const [inputSearch, setInputSearch] = useState('')

  const [rows, setRows] = useState(10)
  const [page, setPage] = useState(1)
  const [listCustomers, setListCustomers] = useState([])
  const [loadingPage, setLoadingPage] = useState(false)
  const [loadingNotify, setLoadingNotify] = useState(false)
  const [account, setAccount] = useState({
    email: '',
    customerCode: '',
    errorNewEmail: false,
    openModal: false
  })
  const [updateOptions, setUpdateOptions] = useState({
    update: false,
    accountIndex: '',
    repeatedEmails: [],
    modalCustomersIsOpen: false
  })

  const [notification, setNotification] = useState({
    notify: '',
    selectOpen: false,
    mro: false,
    offset: false,
    email: '',
    customerCode: '',
    openModal: false,
    emailResp: ''
  })

  const handleSelectOpenClose = () => {
    setNotification({
      ...notification,
      selectOpen: !notification.selectOpen,
    })
  }

  const mountTooltip = (
    createdBy,
    updatedBy,
    userDateUpdated,
    userDateCreated
  ) =>
    `Created by: ${createdBy} - ${userDateCreated} <br/>Updated by: ${updatedBy ? updatedBy : createdBy
    } - ${userDateUpdated}`

  const handleSearchCustomers = (event) => {
    event.preventDefault()
    setLoadingPage(true)

    const findAll = { search: inputSearch, rows, p: page }
    listAll(findAll).then((response) => {
      setListCustomers(response.data)
      setLoadingPage(false)
    })
  }

  const handleExpandedCustomer = async ({ idx, isSaveEditOrDelete }) => {
    let updateListCustomer = listCustomers.accounts
    if (!isSaveEditOrDelete)
      updateListCustomer[idx].expanded = !updateListCustomer[idx].expanded
    if (updateListCustomer[idx].expanded) {
      updateListCustomer[idx].loading = !updateListCustomer[idx].loading
      const getListCustomersEmails = await listCustomersEmails(
        updateListCustomer[idx].id
      )
      updateListCustomer[idx].customers = getListCustomersEmails.data.customers
      updateListCustomer[idx].loading = false
    }
    let listCustomersUpdate = {
      ...listCustomers,
      accounts: updateListCustomer
    }
    setListCustomers(listCustomersUpdate)
  }

  const handleOpenNotification = async (idx) => {
    const customer = listCustomers.accounts[idx]
    const getListCustomersEmails = await listCustomersEmails(
      customer.id
    )
    return getListCustomersEmails.data.customers
  }

  const checkAll = (idx) => {
    let updateListCustomer = listCustomers.accounts
    updateListCustomer[idx].checkAllSend = !updateListCustomer[idx].checkAllSend
    updateListCustomer[idx].customers.forEach((customer) => {
      customer.emails.forEach((email) => {
        email.collectionLetter = updateListCustomer[idx].checkAllSend
      })
    })
    let listCustomersUpdate = { ...listCustomers, accounts: updateListCustomer }
    setListCustomers(listCustomersUpdate)
  }

  const checkAllDaily = (idx) => {
    let updateListCustomer = listCustomers.accounts
    updateListCustomer[idx].checkAllDailySend =
      !updateListCustomer[idx].checkAllDailySend
    updateListCustomer[idx].customers.forEach((customer) => {
      customer.emails.forEach((email) => {
        email.daily = updateListCustomer[idx].checkAllDailySend
      })
    })
    let listCustomersUpdate = { ...listCustomers, accounts: updateListCustomer }
    setListCustomers(listCustomersUpdate)
  }

  const handleOpenModal = async ({ email, customerCode }) => {
    setAccount({
      ...account,
      email,
      customerCode,
      openModal: true
    })
  }

  const handleOpenNotificationModal = async ({ email, customerCode, emailResp }) => {
    setNotification({
      ...notification,
      email,
      customerCode,
      openModal: true,
      emailResp
    })
  }

  const handlerNotification = async () => {
    setLoadingNotify(true)

    const data = {
      emails: notification.email.split(',').map((email) => email.trim()),
      notification: notification.notify,
      code: notification.customerCode,
      mro: notification.mro,
      offset: notification.offset
    }

    try {
      await sendNotification({
        emails: data.emails,
        notification: data.notification,
        code: data.code,
        mro: data.mro,
        offset: data.offset
      })

      Swal.fire(
        t('success'),
        `${data.notification} enviada para ${data.emails}`,
        'success'
      )

    } catch (err) {
      let response = err.response.data.body
      let message = ''

      if (/(Summaries not found)/.test(response))
        message = t('user-notification-not-found-error')
      else if (/(No overdue values found)/.test(response))
        message = t('user-notification-not-found-error')
      else if (/(Document template not generated)/.test(response))
        message = t('user-notification-error')
      else message = t('user-notification-error')

      Swal.fire({
        icon: 'error',
        title: t('error'),
        text: message
      })
    } finally {
      setLoadingNotify(false)
      setNotification({
        ...notification,
        email: '',
        customerCode: '',
        notify: '',
        mro: false,
        offset: false,
        openModal: false,
        selectOpen: false
      })
    }
  }

  const handleSelectChange = (event) => {
    setNotification({
      ...notification,
      notify: event.target.value,
      selectOpen: !notification.selectOpen
    })
  }

  const handleSaveUser = async () => {
    let companyIndex
    listCustomers.accounts.forEach((value, id) => {
      if (value.customers) {
        value.customers.forEach((customer) => {
          if (account.customerCode === customer.code) {
            companyIndex = id
          }
        })
      }
    })

    if (
      !account.email ||
      account.email.length < 5 ||
      !validateEmailFormat(account.email.trim())
    ) {
      setAccount({ ...account, errorNewEmail: true })
    } else {
      setAccount({ ...account, openModal: false, email: '' })
      try {
        await saveUser({
          email: account.email.trim(),
          customerCode: account.customerCode
        })

        Swal.fire(t('success'), t('user-save-success-message'), 'success')

        setAccount({
          ...account,
          email: '',
          customerCode: '',
          openModal: false
        })
      } catch (err) {
        Swal.fire(
          t('error'),
          t(verifySaveUserErrorType(err.response.data.body)),
          'error'
        )
        setAccount({
          ...account,
          email: '',
          customerCode: '',
          openModal: false
        })
      }
    }

    handleExpandedCustomer({ idx: companyIndex, isSaveEditOrDelete: true })
  }

  const handleChangeEmail = async (value) => {
    setAccount({ ...account, email: value })
  }

  useEffect(() => {
    const findAll = { search: inputSearch, rows, p: page }
    listAll(findAll).then((response) => {
      setListCustomers(response.data)
    })
    setRows(10)
    setUpdateOptions({ update: false })
  }, [])

  useEffect(() => {
    if (updateOptions.update) {
      handleExpandedCustomer({
        idx: updateOptions.accountIndex,
        isSaveEditOrDelete: true
      })
    }
  }, [updateOptions])

  return (
    <>
      <Modal
        open={account.openModal}
        onClose={() =>
          setAccount({
            ...account,
            openModal: !account.openModal
          })
        }>
        <Box>
          <div className='modal-modal-title' variant='h6' component='h2'>
            {t('user-new-modal-message')}
          </div>
          <p className='modal-input-title'>Email:</p>
          <InputText
            type='text'
            placeholder={t('user-modal-placeholder')}
            defaultValue={account.email}
            onChange={(input) => handleChangeEmail(input.target.value)}
            className='modal-text'
            required
            error={account.errorNewEmail}
          />
          {account.errorNewEmail ? (
            <>
              <p className='email-format-error'>
                {t('user-email-invalid-format')}
              </p>
            </>
          ) : null}
          <div className='buttons-modal'>
            <Button
              onClick={() =>
                setAccount({
                  ...account,
                  email: '',
                  customerCode: '',
                  openModal: !account.openModal,
                  errorNewEmail: false
                })
              }
              color='secondary'>
              {t('user-cancel-button')}
            </Button>
            <Button color='primary' onClick={handleSaveUser}>
              {t('user-save-button')}
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={notification.openModal}
        onClose={() => !loadingNotify &&
          setNotification({
            ...notification,
            openModal: !notification.openModal
          })
        }>
        <Box>
          <div className='modal-modal-title' variant='h6' component='h2'>
            {t('user-notification')}
          </div>

          <Container style={{ marginTop: '15px' }}>
            <p style={{ padding: '10px' }}>{t('user-notification-description')}</p>
            <CustomerTypeUi >
              <div className='label-position'>
                <FormLabel>{t('user-notification-select')}</FormLabel>
              </div>
              <FormControl>
                 {notification.offset ? (
                    <Select
                    autoWidth
                    open={notification.selectOpen}
                    onClose={handleSelectOpenClose}
                    onOpen={handleSelectOpenClose}
                    value={notification.notify}
                    onChange={handleSelectChange}
                    >
                    <MenuItem style={{ fontSize: 16 }} value={'select'} disabled>
                      {t('user-notification-select')}
                    </MenuItem>
                    <MenuItem style={{ fontSize: 16 }} value={'Step 1'}>
                      Step 1
                    </MenuItem>
                    <MenuItem style={{ fontSize: 16 }} value={'Step 2'}>
                      Step 2
                    </MenuItem>
                  </Select>
                 ) : (<Select
                  autoWidth
                  open={notification.selectOpen}
                  onClose={handleSelectOpenClose}
                  onOpen={handleSelectOpenClose}
                  value={notification.notify}
                  onChange={handleSelectChange}
                  >
                  <MenuItem style={{ fontSize: 16 }} value={'select'} disabled>
                    {t('user-notification-select')}
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'Step 1'}>
                    Step 1
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'Step 2'}>
                    Step 2
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'Step 2.1'}>
                    Step 2.1
                  </MenuItem>
                  <MenuItem style={{ fontSize: 16 }} value={'Step 3'}>
                    Step 3
                  </MenuItem>
                </Select> )}
              </FormControl>
            </CustomerTypeUi>
            <p style={{ padding: '10px' }}>{t('user-notification-checkbox')}
              <Checkbox
                onChange={() => setNotification({
                  ...notification,
                  mro: !notification.mro
                })}
                checked={notification.mro}
              />
            </p>
            <p style={{ padding: '10px' }}>{t('user-notification-offset-checkbox')}
              <Checkbox
                onChange={() => setNotification({
                  ...notification,
                  offset: !notification.offset
                })}
                checked={notification.offset}
              />
            </p>
            <p style={{ padding: '10px' }}>{t('user-notification-resume')}:</p>
            <div style={{ padding: '10px 20px', display: 'grid', gap: '10px' }}>
              <p><strong>{t('user-notification-email')}</strong>: {notification.email}</p>
              <p><strong>{t('user-notification-customer-code')}</strong>: {notification.customerCode}</p>
              <p><strong>{t('user-notification-resp')}</strong>: {notification.emailResp}</p>
              <p><strong>{t('user-notification-notify')}</strong>: {notification.notify}</p>
              <p><strong>{t('user-notification-mro')}</strong>: {notification.mro ? 'Yes' : 'No'}</p>
              <p><strong>{t('user-notification-offset')}</strong>: {notification.offset ? 'Yes' : 'No'}</p>
            </div>
            <p style={{ padding: '10px' }}>{t('user-notification-confirmation')}</p>
          </Container>
          <div className='buttons-modal'>
            <Button
              onClick={() =>
                setNotification({
                  ...notification,
                  email: '',
                  customerCode: '',
                  openModal: !notification.openModal,
                  notify: '',
                  mro: false,
                  offset: false,
                  selectOpen: false
                })
              }
              disabled={loadingNotify}
              color='secondary'>
              {t('user-notification-cancel')}
            </Button>
            <Button color='primary' onClick={handlerNotification} disabled={loadingNotify}>
               { loadingNotify ? t('user-notification-sending') : t('user-notification-send')}
            </Button>
          </div>
        </Box>
      </Modal >
      <Container>
        <GridContainer container direction='row'>
          <Grid item xs={12} md={7} style={{ display: 'flex' }}>
            <TitlePage>
              <h3>Companies</h3>
              <small>
                (Showing{' '}
                {listCustomers.accounts ? listCustomers.accounts.length : 0}{' '}
                entries)
              </small>
            </TitlePage>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormSearchCustomer onSubmit={handleSearchCustomers}>
              <InputText
                type='text'
                placeholder={t(
                  'customer-setup-config-email-input-search-placeholder'
                )}
                defaultValue={inputSearch}
                onChange={(input) => setInputSearch(input.target.value)}
              />
              <ButtonSearch type='submit' />
            </FormSearchCustomer>
          </Grid>
        </GridContainer>

        {loadingPage ? <Loading size='40px' /> : null}

        {listCustomers.accounts && loadingPage === false
          ? listCustomers.accounts.map((account, idxAccount) => (
            <GridContainer container direction='row' key={`${account.id}`}>
              <Grid item className='total-row'>
                <ExpansionPanel
                  expanded={account.expanded}
                  onChange={() =>
                    handleExpandedCustomer({ idx: idxAccount })
                  }>
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{ marginRight: 5, fontSize: 24 }}
                      />
                    }
                    aria-controls={`panel-header`}
                    id={`panel-header`}>
                    <div className='i-collapsible-top'>
                      <h3>
                        {account.id} - {account.name}
                        <div className='notification-actions'>
                          <NotificationsMenu account={account} handleOpen={() => handleOpenNotification(idxAccount)} handlerNotify={handleOpenNotificationModal} />
                        </div>
                      </h3>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    {account.loading === false ? (
                      <table className='table table-customers'>
                        <thead>
                          <tr>
                            <th align='left'>MFIR</th>
                            <th align='left'>COMPANY</th>
                            <th align='left'>BLOCKED TO DISPUTE</th>
                            <th align='left'>E-MAIL</th>
                            <th align='left'>STATUS</th>
                            <th align='left'>ORIGIN</th>
                            <th>
                              <label>
                                AR REPORT?
                                <Checkbox
                                  checked={account.checkAllSend}
                                  onChange={() => checkAll(idxAccount)}
                                />
                              </label>
                            </th>
                            <th>
                              <label>
                                INVOICES DAILY?
                                <Checkbox
                                  checked={account.checkAllDailySend}
                                  onChange={() => checkAllDaily(idxAccount)}
                                />
                              </label>
                            </th>
                            <th>LAST UPDATE</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {account.customers
                            ? account.customers.map((customer, idx) => (
                              <tr
                                key={`${idx}`}
                                className='table-body-customers'>
                                <td className='table-bottom-items'>
                                  {customer.code}
                                </td>
                                <td className='table-bottom-items'>
                                  {customer.name}
                                </td>
                                <td className='table-bottom-items'>
                                  <CheckboxDispute
                                      key={`${customer.code}-dispute`}
                                      customer={customer.code}
                                      isDispute={customer.isDispute}
                                      disabled={false}
                                    />
                                </td>
                                <td
                                  align='left'
                                  className='table-bottom-items'>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-email-${idx}`}
                                        className='customers-emails'>
                                        <td align='left'>
                                          {row.email ? row.email : ''}
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                </td>
                                <td
                                  align='left'
                                  className='table-bottom-items'>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-email-${idx}`}
                                        className='customers-emails'>
                                        <td align='left'>
                                          {row.usersFromCustomersId
                                            ? t('user-active-text')
                                            : t('user-desactive-text')}
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                </td>
                                <td
                                  align='left'
                                  className='table-bottom-items'>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-origin-${idx}`}
                                        className='notification-origins'>
                                        <td align='left'>
                                          {row.origin ? row.origin : ''}
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                </td>
                                <td
                                  align='center'
                                  className='table-bottom-items'>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-ck-${idx}`}>
                                        <td
                                          align='center'
                                          className='send-report-checkbox'>
                                          <CheckboxEmail
                                            key={`${row.id}-1`}
                                            email={row}
                                            checkboxAllSend={
                                              row.usersFromCustomersId
                                                ? row.collectionLetter
                                                : ''
                                            }
                                            disabled={
                                              !row.usersFromCustomersId
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                </td>
                                <td
                                  align='center'
                                  className='table-bottom-items'>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-ck-${idx}`}>
                                        <td
                                          align='center'
                                          className='send-report-checkbox'>
                                          <CheckboxEmailDaily
                                            key={`${row.id}-1`}
                                            email={row}
                                            checkboxAllSend={
                                              row.usersFromCustomersId
                                                ? row.daily
                                                : ''
                                            }
                                            disabled={
                                              !row.usersFromCustomersId
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                </td>
                                <td
                                  align='center'
                                  className='table-bottom-items'>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-up-${idx}`}>
                                        <td
                                          align='center'
                                          className='last-update-icons'>
                                          <Tooltip
                                            textTooltip={renderHTML(
                                              mountTooltip(
                                                row.createdBy,
                                                row.updatedBy,
                                                row.userDateUpdated,
                                                row.userDateCreated
                                              )
                                            )}>
                                            <IconButtonUi>
                                              <PersonIcon />
                                            </IconButtonUi>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                </td>
                                <td
                                  align='center'
                                  style={{ paddingTop: '20px' }}>
                                  {customer.emails
                                    ? customer.emails.map((row, idx) => (
                                      <tr
                                        key={`${customer.code}-up-${idx}`}>
                                        <td
                                          align='center'
                                          className='table-buttons'>
                                          <Container>
                                            <ActionsMenu
                                              email={row.email}
                                              idxAccount={idxAccount}
                                              userId={row.userId}
                                              emailId={row.id}
                                              usersFromCustomersId={
                                                row.usersFromCustomersId
                                              }
                                              customerCode={customer.code}
                                              setUpdateOptions={
                                                setUpdateOptions
                                              }
                                              notificationModal={handleOpenNotificationModal}
                                              emailResp={customer.resp}
                                              origin={row.origin}
                                            />
                                          </Container>
                                        </td>
                                      </tr>
                                    ))
                                    : ''}
                                  <tr>
                                    <td className='new-email'>
                                      <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() =>
                                          handleOpenModal({
                                            customerCode: customer.code
                                          })
                                        }>
                                        {t('user-new-user-button')}
                                      </Button>
                                    </td>
                                  </tr>
                                </td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    ) : (
                      <Loading size='45px' />
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </GridContainer>
          ))
          : null}

        <br />

        <GridContainer container direction='row'>
          <Grid item xs={12}>
            <ContainerPagination>
              <Pagination
                disabled={listCustomers.totalPages === 1 ? true : false}
                count={listCustomers.totalPages}
                color='primary'
                onChange={(event, page) => {
                  setPage(page)
                  setLoadingPage(true)
                  const findAll = { search: inputSearch, rows, p: page }
                  listAll(findAll).then((response) => {
                    setListCustomers(response.data)
                    setLoadingPage(false)
                  })
                }}
              />
            </ContainerPagination>
          </Grid>
        </GridContainer>
      </Container>
    </>
  )
}
